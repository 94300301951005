window.PhotoGallery = (galleryFields) => {
	return {
		imageGalleryOpened: false,
		imageGalleryActiveUrl: null,
		imageGalleryImageIndex: null,
		imageGallery: null,

		init () {
			this.imageGallery = galleryFields;
			console.log(this.imageGallery);
		},

		imageGalleryOpen(event) {
			this.imageGalleryImageIndex = event.target.dataset.index;
			this.imageGalleryActiveUrl = event.target.src;
			this.imageGalleryOpened = true;
		},
		imageGalleryClose() {
			this.imageGalleryOpened = false;
			setTimeout(() => this.imageGalleryActiveUrl = null, 300);
		},
		imageGalleryNext(){
			this.imageGalleryImageIndex = (this.imageGalleryImageIndex == this.imageGallery.length) ? 1 : (parseInt(this.imageGalleryImageIndex) + 1);
			this.imageGalleryActiveUrl = this.$refs.gallery.querySelector('[data-index=\'' + this.imageGalleryImageIndex + '\']').src;
		},
		imageGalleryPrev() {
            console.log(this.$refs);
			this.imageGalleryImageIndex = (this.imageGalleryImageIndex == 1) ? this.imageGallery.length : (parseInt(this.imageGalleryImageIndex) - 1);
			this.imageGalleryActiveUrl = this.$refs.gallery.querySelector('[data-index=\'' + this.imageGalleryImageIndex + '\']').src;
		}
	};
};
